<template>
  <div id="sidenav">
    <a class="go-back" @click="$router.back()"><i class="fal fa-times"></i> Fermer</a>
    <hr />
    <div id="search-box-widget">
      <input
        @keyup.enter="onSearch"
        v-model.trim="search"
        type="search"
        class="autocomplete-input"
        maxlength="512"
        autocapitalize="off"
        autcomplete="off"
        autocorrect="off"
        placeholder="Oeuvre par mot clé..."
      />
    </div>

    <h6 class="menu-title">Explorer</h6>
    <ul class="list">
      <li><router-link to="/oeuvres">Oeuvres</router-link></li>
      <li><router-link to="/artistes">Artistes</router-link></li>
      <li><a @click="openCategory('peinture')">Peinture</a></li>
      <li><a @click="openCategory('sculpture')">Scuplture</a></li>
      <li><a @click="openCategory('photographie')">Photographie</a></li>
      <li><a @click="openCategory('dessin')">Dessin</a></li>
      <li><a @click="openCategory('tableau')">Tableau</a></li>
    </ul>
    <h6 class="menu-title">COMPTE</h6>

    <ul class="list">
      <li v-if="!isLoggedIn">
        <a data-toggle="modal" data-target="#signModal"
          >Se connecter / S'inscrire</a
        >
      </li>
      <li v-if="isLoggedIn">
        <router-link to="/mon-compte">Mon Profil</router-link>
      </li>
      <li v-if="isArtist">
        <router-link to="/dashboard">Ma Boutique</router-link>
      </li>
      <li><router-link to="/panier">Mon Panier</router-link></li>
      <li><router-link to="/mes-achats">Mes Achats</router-link></li>
      <li>
        <router-link to="/favoris/oeuvres">Mes oeuvres favories</router-link>
      </li>
      <li>
        <router-link to="/favoris/artistes">Mes artistes favoris</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isLoggedIn", "user"]),
    isArtist() {
      if (
        this.isLoggedIn &&
        this.user &&
        this.user.role == "artist"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#sidenav {
  padding: 20px;
}
.go-back {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 28px;
    margin-right: 6px;
  }
  &:hover {
    color: #444;
  }
}
.list {
  list-style-type: none;

  li {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    padding-left: 10px;
    font-weight: 500;
    font-size: 13px;

    a:hover {
      // text-decoration: none;
      color: var(--primary);
    }
  }
}
.menu-title {
  font-size: 12px;
  color: #555;
  margin-top: 30px;
  margin-bottom: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
